<template>
  <div class="view_section">
    <div class="game_view">
        <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "LeisureGameView"
  }
</script>

<style scoped>
  #gamefram {
    width: 100%;
    height: 641px;
    border: 0;
    margin: 0 auto;
  }
  .game_view .view{
    height: 100%;
    margin-left: -200px;
    transform: scale(0.9, 0.9);
  }

  @media screen and (max-width: 800px) {
    .game_view .view{
      width: 960px;
      transform: scale(0.5, 0.5);
      margin-left: -280px;
      margin-top: -100px;
    }
    .game_view .view iframe {
      width: 100%;
      transform: scale(0.8, 0.8);
    }
  }
  @media screen and (min-width:100px) and (max-width:500px){
    #gamefram {
      width: 100%;
      height: 641px;
      border: 0;
    }
  }
  @media screen and (min-width:501px) and (max-width:600px){
    #gamefram {
      width: 100%;
      height: 641px;
      border: 0;
      margin-left: 130px;
      margin-top: 35px;
    }
  }
  @media screen and (min-width:601px) and (max-width:750px){
    #gamefram {
      width: 100%;
      height: 641px;
      border: 0;
      margin-left: 250px;
      margin-top: 35px;
    }
  }
  @media screen and (min-width:751px) and (max-width:850px){
    #gamefram {
      width: 100%;
      height: 641px;
      border: 0;
      margin-left: 350px;
      margin-top: 45px;
    }
  }
  @media screen and (min-width:851px) and (max-width:4000px){
    #gamefram {
      width: 100%;
      height: 641px;
      border: 0;
      margin: 10px;
    }
  }

</style>