<template>
  <div class="time_box">
    <h3><slot name="title"></slot> {{sequenceText}} <button class="button_type01 refresh_icon" @click="f5()" ><i class="fa fa-refresh"></i></button></h3>
    <div class="time_area"><span class="ico_time"></span>{{getTime}}</div>

  </div>
</template>

<script>
  export default {
    name: "LeisureTimerComp",
    props: {
      leisureGameInfo: {
        type:Object,
        default(){
          return {}
        },
      },
    },
    data() {
      return {
        compairTime: this.$moment(new Date(2020, 0, 1, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss'),
        limitTime: this.compairTime,
        sequenceText: '',
        timer:null
      }
    },
    computed: {
      getTime() {
        return this.$moment(this.limitTime).format('mm분ss초')
      }
    },
    methods: {
      f5(){
        if (this.timer) clearTimeout(this.timer);
        this.limitTime = this.compairTime
        this.$emit('refreshTimer');
      },
      setSequenceText(sequenceText = '경기 초기화 중입니다') {
        this.sequenceText = sequenceText
      },
      startTimeCount() {
        //
        let sequenceText = this.$moment(this.leisureGameInfo.leisureGame.startTime).format('MM월DD일 [') + this.leisureGameInfo.leisureGame.sequence + ' 회차]'
        this.setSequenceText(sequenceText)
        //this.limitTime = this.$moment(this.compairTime).add(3, 'seconds')
        this.limitTime = this.$moment(this.compairTime).add(this.leisureGameInfo.betStopLimitSecond, 'seconds')
        this.timer = setInterval(() => {
          this.limitTime = this.$moment(this.limitTime).subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
          let diff = this.$moment(this.limitTime).diff(this.compairTime);
          if (diff <= 0) {
            if (this.timer) clearTimeout(this.timer);
            this.limitTime = this.compairTime
            this.$emit('timerStop');
            this.setSequenceText()
          }
        }, 1000)
      }
    },
    created() {

    }
  }
</script>

<style scoped>

</style>