<template>
  <div class="leisrue_masker" :class="{'show':showMasker}">
    <div class="icon"><i class="fa fa-lock"></i></div>
  </div>
</template>

<script>
  export default {
    name: "LeisureBetMasker",
    props:{
      showMasker:false
    }
  }
</script>

<style scoped>
  .leisrue_masker{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1c1c25;
    opacity: .8;
    z-index: 200;
    top: 0;
    left: 0;
    display: none;
    transition: 200ms all;
  }
  .leisrue_masker .icon{
    position: absolute;
    font-size: 100px;
    top: 30%;
    left: 43%;
  }
  @media screen and (max-width: 800px) {
    .leisrue_masker .icon{
      left: 41%;
    }
  }
  .show{
    display: block;
  }
</style>